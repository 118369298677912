const ethers = require('ethers');

const loadingSpinner = `
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">Loading...</span>
`;

var walletType;
var infuraProvider;
var provider;
var signer;
var network;
var onConnectCallbacks = [];

const INFURA_PROJECT_ID = '3e369be9e69f4b3984930a8f8f302834';
const INFURA_PROJECT_SECRET = 'c425e67b57524b9d94db68ecbac5ead2';

export async function connect() {
    if (provider === undefined)
        return;
    $('#login-button').prop('disabled', true);
    $('#login-button').html(loadingSpinner);
    const connected = await isConnected();
    $('#login-button').prop('disabled', false);
    $('#login-button').html('Login');
    return connected;
}

export async function isConnected() {
    var signer;
    try {
        signer = await getSigner();
    } catch (error) {
        console.error(error);
    }
    $('#auth-loading').hide();
    if (signer === undefined) {
        $('#auth-false').show();
        $('#auth-true').hide();
        return false;
    }
    $('#auth-false').hide();
    $('#auth-true').show();
    for (let callback of onConnectCallbacks)
        callback();
    return true;
}

export async function getChainId() {
    try {
        if (network === undefined) {
            if (provider === undefined)
                return undefined;
            network = await provider.getNetwork();
        }
    } catch (error) {
        await providerInit(walletType);
        network = await provider.getNetwork();
    }
    return network.chainId;
}

export async function getSigner() {
    if (provider === undefined)
        return undefined;
    if (signer === undefined)
        signer = await provider.getSigner();
    return signer;
}

export async function getReadonlyProvider(networkish = undefined) {
    //if (walletType == 'metamask')
    //    return await getSigner();
    if (infuraProvider === undefined) {
        if (provider === undefined && networkish === undefined)
            return undefined;
        if (networkish === undefined)
            networkish = await getChainId();
        else
            network = ethers.Network.from(networkish);
        infuraProvider = new ethers.InfuraProvider(networkish, INFURA_PROJECT_ID, INFURA_PROJECT_SECRET);
    }
    return infuraProvider;
}

export async function providerInit(wallet) {
    walletType = wallet;
    if (wallet == 'metamask') {
        walletType = 'metamask';
        if (window.ethereum)
            provider = new ethers.BrowserProvider(window.ethereum);
        else
            $('#auth-loading').html('Please install MetaMask or another Ethereum wallet.');
        $('#login-button').click(connect);
    } else if (wallet.startsWith('readonly:')) {
        walletType = 'readonly';
        const networkish = wallet.split(':')[1];
        provider = await getReadonlyProvider(networkish);
        signer = {
            address: wallet.split(':')[2],
            provider: provider,
        };
    } else if (wallet.includes(':')) { // wallet is "networkish:private_key"
        walletType = 'private_key';
        const networkish = wallet.split(':')[0];
        const privateKey = wallet.split(':')[1];
        provider = await getReadonlyProvider(networkish);
        signer = new ethers.Wallet(privateKey, provider);
    } else {
        throw new Error('Invalid wallet format. Please use "metamask"|"readonly:wallet"|"networkish:private_key"');
    }
    return await isConnected();
}

export function onWalletConnect(callback) {
    onConnectCallbacks.push(callback);
}

export default { connect, isConnected, getChainId, getSigner, getReadonlyProvider, providerInit, onWalletConnect };
