import { NetworkBiMap } from "../chains.js";

const ethers = require('ethers');

export const addresses = new NetworkBiMap({
    'mainnet': {
        // scanners
        'etherscan': 'https://etherscan.io',

        // contracts
        'compliantTreasury': ethers.ZeroAddress,

        // currencies
        'ETH': ethers.ZeroAddress,
        'USDC': '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        'RWA': ethers.ZeroAddress,

        // invoicers
        'Securely': '0xB13807B66c0c03CbeBaBc8363e133216db0D33C0',
    },
    'polygon-mainnet': {
        // scanners
        'etherscan': 'https://polygonscan.com',

        // contracts
        'compliantTreasury': ethers.ZeroAddress,

        // currencies
        'MATIC': ethers.ZeroAddress,
        'USDC': '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        'RWA': ethers.ZeroAddress,

        // invoicers
        'Securely': '0xB13807B66c0c03CbeBaBc8363e133216db0D33C0',
    },
    'amoy': {
        // scanners
        'etherscan': 'https://amoy.polygonscan.com',

        // contracts
        'compliantTreasury': '0x78F10c988Bb44cD5A17E4A61E3B69184e6F7d790',

        // currencies
        'ETH': ethers.ZeroAddress,
        'USDC': '0x0Be5c47f786Ad019bA232a4729bb023EaC27dCeD',
        'RWA': '0xa98BE189bCF4c79a4e8f9e3b51746D0bdd7a6Fca',

        // invoicers
        'Securely': '0xB13807B66c0c03CbeBaBc8363e133216db0D33C0',
    },
    'arbitrum-mainnet': {
        // scanners
        'etherscan': 'https://arbiscan.io',

        // contracts
        'compliantTreasury': ethers.ZeroAddress,

        // currencies
        'ETH': ethers.ZeroAddress,
        'USDC': '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        'RWA': ethers.ZeroAddress,

        // invoicers
        'Securely': '0xB13807B66c0c03CbeBaBc8363e133216db0D33C0',
    },
    'arbitrum-sepolia': {
        // scanners
        'etherscan': 'https://sepolia.arbiscan.io',

        // contracts
        'compliantTreasury': ethers.ZeroAddress,

        // currencies
        'ETH': ethers.ZeroAddress,
        'USDC': ethers.ZeroAddress,
        'RWA': ethers.ZeroAddress,

        // invoicers
        'Securely': '0xB13807B66c0c03CbeBaBc8363e133216db0D33C0',
    },
    'sepolia': {
        // scanners
        'etherscan': 'https://sepolia.etherscan.io',

        // contracts
        'compliantTreasury': '0xBea4F709B43b842d22B93A12776e05bc6958F29D',

        // currencies
        'ETH': ethers.ZeroAddress,
        'USDC': '0xeaF82BBEf7973E042e0799cf243C39Dc5bCe866F',
        'RWA': '0x90c109c160B47acFb990B2a8472360a92FeE4402',

        // invoicers
        'Securely': '0xB13807B66c0c03CbeBaBc8363e133216db0D33C0',
        'John Doe (Securely)': '0xf3AAf290F56283f559cd20546474bE4939B0De9A',
    },
});

export default addresses;
